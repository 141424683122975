export class Shipment {
  // -------------------------Initializing values-------------------------
  static pending = 3;
  static posted = 4;
  static released = 5;
  static delivered = 6;
  static cancelled = 7;
  static canceled = 7;

  static not_delivered = 8;

  // -------------------------Defining business rules-------------------------
  static stepperMapping = [
    { title: 'Recebemos seu Pedido' },
    { title: 'O seu pagamento foi aprovado!' },
    { title: 'Sua Nota Fiscal foi emitida' },
    { title: 'Aguardando postagem' },
    { title: 'Postado. Aguardando envio' },
    { title: 'Em trânsito' },
    { title: 'Entregue' }
  ]


  // -------------------------Name mapping functions-------------------------
  static getLowerStatus = (array) => {
    return array.reduce((accumulator, item) => {
      const status = item.status

      if (Shipment[status] >= accumulator) {
        accumulator = Shipment[status]
        console.log(Shipment[status],accumulator)
      }

      return accumulator
    }, Shipment.pending);
  }

  static getTrackingCode = (array) => {
    const biggerStatusItem = array.reduce((accumulator, item) => {
      const status = item.status

      if (Shipment[status] >= accumulator.status && item.melhorenvio_tracking) {
        accumulator.status = Shipment[status]
        accumulator.code = item.melhorenvio_tracking
      }

      return accumulator
    }, { status: Shipment.pending, code: '' });

    return biggerStatusItem.code
  }

  static getPendingIds = (array) => {
    return array.filter(item => item.status === 'pending').map(item => item.id);
  }

  static getLabel = (array) => {
    return array.filter(item => item.status === 'pending').map(item => item.id);
  }

  static isPostable = (array) => {
    return array.some(item => item.status === 'pending');
  }

  static isCancellable = (array) => {
    return array.every(item => item.cancellable && item.status !== 'canceled');
  }

}