import axios from 'axios';
import { URL } from '../variables';

const PREFIX_URL = URL + 'api/' + 'shipment/'

const token = localStorage.getItem('token');

const headers = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export const createShipmentCheckout = async (shipment_ids) => {
  return axios.post(PREFIX_URL + 'checkout', { shipment_ids }, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
}


export const createShipmentLabel = async (shipment_ids) => {
  return axios.post(PREFIX_URL + 'generate_label', { shipment_ids }, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
}