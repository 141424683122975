import React from 'react'
import PropTypes from 'prop-types'

const CircularProgress = ({ className, width }) => {
  return (
    <div className={className} style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width }}>
      <div className="spinner-border spinner-sm text-white" role="status"></div>
    </div>
  )
}


CircularProgress.defaultProps = {
  className: '',
  width: 200,
}

CircularProgress.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number
}

export default CircularProgress