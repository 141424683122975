import React, { Component } from 'react';
import Modal from 'react-modal';
import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import OwlCarousel from '../../features/owl-carousel';

import { addToCart, toggleWishlist, addToCompare, closeQuickViewModal } from '../../../actions';
import { findIndex, quantityInputs, isIEBrowser, safeContent } from '../../../utils/utils';
import { URL, URL_FRONT } from '../../../variables';
import {
    FacebookShareButton,
    FacebookIcon,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from "react-share";
import { maskPriceDecimal } from '../../../Auxiliar/Masks';
import { Helmet } from 'react-helmet';
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        overflowY: 'scroll',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

Modal.setAppElement('#root');

class QuickView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            loading: false,
            quantidade: 1,
            loading_wish: false,
            show_face: false

        };
        this.openLightBox = this.openLightBox.bind(this);
    }

    componentDidMount() {
        // quantityInputs();
        this.setState({ show_face: true });
    }

    salvar_wishlist(product, wish) {
        if (this.props.token == undefined) {
            this.props.toggleWishlist(product, wish);
            return;
        }
        this.setState({ loading_wish: true, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/lista_desejos/salvar_item_wish/${product.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: false, msgErro: erro });
                }
                else {
                    this.props.toggleWishlist(product, wish);

                    this.setState({ loading_wish: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    salvar(id, quantidade) {
        if (this.props.token == undefined) {
            this.props.addToCart(this.props.productDetail, document.querySelector("#qty").value);
            return;
        }
        this.setState({ loading: true, msgErro: '' });
        let form = new FormData();
        console.log(id, quantidade);
        fetch(`${URL}api/carrinho/salvar_item_carrinho/${id}/${quantidade}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro });
                }
                else {
                    this.props.addToCart(this.props.productDetail, document.querySelector("#qty").value);
                    this.setState({ loading: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    openLightBox(e, index) {
        this.setState({ isOpen: true, photoIndex: index });
        e.preventDefault();
    }

    afterOpenModal() {
        // quantityInputs();
        this.setState({ quantidade: 1 });
    }




    render() {
        const { showModal, productDetail, addToCart, toggleWishlist, addToCompare, closeQuickViewModal } = this.props;
        console.log(showModal);
        console.log(productDetail);

        if (!productDetail || !productDetail.name)
            return ('');

        let ratings = productDetail.ratings * 20;
        let images = productDetail.fotos;

        const { photoIndex, isOpen } = this.state;
        let wish = false;
        for (let i = 0; i < this.props.wishlist.length; i++) {
            if (this.props.wishlist[i].id == productDetail.id) {
                wish = true;
            }
        }
        return (
            <Modal isOpen={showModal}
                contentLabel="QuickView"
                onAfterOpen={() => this.afterOpenModal()}
                onRequestClose={closeQuickViewModal}
                shouldFocusAfterRender={false}
                style={customStyles}
                className="container quickView-container" id="product-quickview">
                <Helmet>
                    <meta property="og:url" content={`${URL_FRONT}product/default/${productDetail.id}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${productDetail.name}`} />
                    <meta property="og:description" content={`${productDetail.descricao}`} />
                    <meta property="og:image" content={URL + productDetail.fotos[0].foto} />
                </Helmet>
                <div className="quickView-content" >
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="row">

                                <div className="product-left" id="owl-dots">
                                    {
                                        productDetail.fotos.map((item, index) => (
                                            <button className={`carousel-dot ${0 === index ? 'active' : ''}`} key={index}>
                                                <img src={URL + item.foto} alt="dot" />
                                            </button>
                                        ))
                                    }
                                </div>

                                <div className="product-right">
                                    <OwlCarousel adClass="owl-quickview owl-carousel owl-theme owl-nav-inside owl-light mb-0 owl-loaded owl-drag" carouselOptions="{dots: false,nav:false,dotsContainer: #owl-dots, responsive:{900:{nav:true,dots:true}} " id="owl_quickview">
                                        {
                                            productDetail.fotos.map((item, index) => (
                                                <div className="intro-slide" key={index}>
                                                    <img src={URL + item.foto} alt="Desc" />
                                                    <Link to="popup/fullscreen.html" className="btn-fullscreen" onClick={(e) => this.openLightBox(e, parseInt(index))}>
                                                        <i className="icon-arrows"></i>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </OwlCarousel>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">

                            <a href={`${URL_FRONT}product/default/${productDetail.id}`}><h2 className="product-title" dangerouslySetInnerHTML={safeContent(productDetail.name)}></h2></a>

                            {0 === productDetail.estoque ?
                                <div className="product-price">
                                    <span className="out-price">{maskPriceDecimal(productDetail.price)}</span>
                                </div> :

                                productDetail.sale_price != null ?
                                    <div className="product-price" style={{ display: 'block' }}>
                                        <span className="old-price" style={{ fontSize: 16 }}>{maskPriceDecimal(productDetail.price)}</span>
                                        <br />
                                        <span className="new-price" style={{ fontWeight: 'bold', color: '#19386e' }}>{maskPriceDecimal(productDetail.sale_price)}</span>
                                    </div> :
                                    <div className="product-price" style={{ fontWeight: 'bold', color: '#19386e' }}>{maskPriceDecimal(productDetail.price)}</div>
                            }
                            {productDetail.avaliacao_total != null && <div className="ratings-container">
                                <div className="ratings">
                                    <div className="ratings-val" style={{ width: parseInt((100 / 5) * productDetail.avaliacao_total) + "%" }}></div>
                                </div>
                                <span className="ratings-text">({productDetail.avaliacoes.length} Avaliações )</span>
                            </div>}

                            <p className="product-txt" style={{ whiteSpace: 'pre-line' }}>{productDetail.descricao}</p>

                            {productDetail.variants ?
                                (productDetail.variants[0].image || productDetail.variants[0].model) ?
                                    <div className="details-filter-row product-nav product-nav-thumbs">
                                        {productDetail.variants.map((vari, i) => {
                                            return (
                                                <Link to="#" key={i} className={0 === i ? 'active' : ''}>
                                                    <img src={process.env.PUBLIC_URL + '/' + (vari.image ? vari.image : vari.model ? vari.model : '')} alt="product desc" />
                                                </Link>)
                                        })}
                                    </div> :

                                    <div className="details-filter-row product-nav product-nav-dots">
                                        {productDetail.variants.map((vari, i) =>
                                            <Link to="#" key={i} className={0 === i ? 'active' : ''} style={{ backgroundColor: vari.rgb }}>
                                            </Link>
                                        )}
                                    </div> : ''
                            }

                            {/* { productDetail.tamanhos.length>0 ?
                                <div className="details-filter-row details-row-size" style={{display:"block"}}>
                                    <label htmlFor="size">Tamanhos:</label>
                                    <div className="select-custom">
                                        <select name="size" id="size" className="form-control" defaultValue="#">
                                            <option value="#">Selecione um tamanho</option>
                                            { productDetail.tamanhos.map( (item, index) => (
                                                <option value={ item.id } key={ index }> { item.name } </option>
                                            )) }
                                        </select>
                                    </div>
                                </div> : ''
                            } */}
                            <input id="qty" value={this.state.quantidade} hidden onChange={() => { }} />

                            {0 != productDetail.estoque && <div style={{ display: 'flex' }}>

                                <div className="btn-qty-custom-left" onClick={(e) => {
                                    if (this.state.quantidade - 1 > 0) {
                                        this.setState({ quantidade: this.state.quantidade - 1 });
                                    }
                                }}>
                                    -
                                </div>
                                <div className="input-qty-custom">
                                    {this.state.quantidade}
                                </div>
                                <div className="btn-qty-custom-right" onClick={(e) => {
                                    if (this.state.quantidade + 1 <= 10 && this.state.quantidade<parseInt(productDetail.estoque)) {
                                        this.setState({ quantidade: this.state.quantidade + 1 });
                                    }
                                }}>
                                    +
                                </div>
                            </div>}


                            {/* <div className="details-filter-row details-row-size" style={{ display: "block" }}>
                                <label htmlFor="qty">Quantidade:</label>
                                <div className="product-details-quantity">
                                    {0 != productDetail.estoque && <select id="qty" style={{ paddingLeft: '40%' }} className="form-control" value={this.state.quantidade} onChange={(e) => {
                                        this.setState({ quantidade: e.target.value });
                                    }}>
                                        {(() => {
                                            let elements = [];
                                            for (let i = 1; i <= productDetail.estoque && i <= 10; i++) {
                                                elements.push(<option key={i} value={i}>{i}</option>)
                                            }
                                            return elements;
                                        })()}



                                    </select>}
                                    {0 == productDetail.estoque && <select id="qty" style={{ paddingLeft: '40%' }} className="form-control" defaultValue={this.state.quantidade}>

                                        <option value="0">0</option>

                                    </select>}
                                </div>
                            </div> */}

                            <div className="product-details-action">

                                {this.state.loading == false && 0 != productDetail.estoque && <button className="btn-product btn-cart" onClick={(e) => { e.preventDefault(); this.salvar(productDetail.id, document.querySelector("#qty").value) }} style={{ minHeight: isIEBrowser() ? '44px' : 'auto' }}>
                                    <span>Comprar</span>
                                </button>}
                                {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}

                                {0 == productDetail.estoque && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <span style={{
                                        textAlign: 'center', textAlign: 'center',
                                        fontSize: '1.8rem',
                                        fontWeight: 600
                                    }}>Produto Indisponível</span>
                                </div>}

                                <div className="details-action-wrapper" style={{ marginTop: 10 }}>

                                    {this.state.loading_wish == false && <button className={`btn-product btn-wishlist ${wish ? 'added-to-wishlist btn_wish' : ''}`}
                                        onClick={() => this.salvar_wishlist(productDetail, wish)}
                                        title={wish ? "Remover da lista de desejos" : "Adicionar na lista de desejos"}
                                        style={{ minHeight: isIEBrowser() ? '20px' : 'auto' }}
                                    >
                                        <span>{wish ? "Remover da lista de desejos" : "Adicionar na lista de desejos"}</span>
                                    </button>}

                                    {this.state.loading_wish == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div></div>}

                                    {/* <button className="btn-product btn-compare" 
                                        title="Compare" 
                                        onClick={ () => addToCompare(productDetail) }
                                        style={ { minHeight: isIEBrowser() ? '20px' : 'auto' } } >
                                        <span>Add to Compare</span>
                                    </button> */}
                                </div>
                            </div>

                            <div className="product-details-footer">
                                <div className="product-cat">
                                    <span>Categorias:</span>
                                    {/* <Link to={"/shop/sidebar/list?cat_id=" + cat.id}>{productDetail.categorias[0].name}</Link> */}
                                    {productDetail.categorias.map((cat, index) => (
                                        <span key={index} className="mr-0">
                                            <Link onClick={() => closeQuickViewModal()} to={"/shop/sidebar/list?cat_id=" + cat.id}>{cat.name}</Link>
                                            {index < productDetail.categorias.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </div>


                                <div className="social-icons social-icons-sm">
                                    <span className="social-label">Compartilhar:</span>
                                    {/* <Link to="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></Link> */}
                                    {this.state.show_face == true && <FacebookShareButton url={`${URL_FRONT}product/default/${productDetail.id}`}>
                                        <div className="social-icon" title="Facebook" ><i className="icon-facebook-f"></i></div>
                                    </FacebookShareButton>}
                                    {/* <Link to="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></Link>
                                    <Link to="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></Link>
                                    <Link to="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => closeQuickViewModal()}><span>×</span></button>

                {isOpen && (
                    <Lightbox
                        mainSrc={URL + images[photoIndex].foto}
                        nextSrc={URL + images[(photoIndex + 1) % images.length].foto}
                        prevSrc={URL + images[(photoIndex + images.length - 1) % images.length].foto}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}

            </Modal>
        );
    }
}
const mapStateToProps = (state, ownprops) => {


    return {
        showModal: state.data.quickView,
        productDetail: state.data.productDetail,
        wishlist: state.wishlist.list,
        token: state.user.token

    };
}

export default connect(mapStateToProps, { addToCart, toggleWishlist, addToCompare, closeQuickViewModal })(QuickView);