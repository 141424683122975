import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { quantityInputs, isIEBrowser, isEdgeBrowser, findIndex } from '../../../../utils/utils';
import {
    FacebookShareButton,
    FacebookIcon,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from "react-share";
import { replaceCart } from '../../../../actions';
import { connect } from 'react-redux';
import { URL, URL_FRONT } from '../../../../variables';
import { maskCep, maskPriceDecimal } from '../../../../Auxiliar/Masks';

class ProductDetailOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            quantidade: 1,
            loading_wish: false,
            show_face: false,
            cep: '',
            precos_prazos: [],
            shipping: {},
            volumes: [],
            msgErroCep: '',
            loading_cep: false
        }
    }

    componentDidMount() {
        // quantityInputs();
        // this.setState({show_face:true});
    }

    componentDidUpdate(props) {
        if (this.props.product.id != props.product.id) {
            this.setState({ quantidade: 1 });
        }
    }

    salvar_wishlist(product) {
        if (this.props.token == undefined) {
            this.props.onToggleWishlistClick(product)
            return;
        }
        this.setState({ loading_wish: true, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/lista_desejos/salvar_item_wish/${product.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: false, msgErro: erro });
                }
                else {
                    this.props.onToggleWishlistClick(product)

                    this.setState({ loading_wish: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    get_preco_prazo(cep, cidade) {
        fetch(`${URL}api/get_preco_prazo_produto`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                produto: { ...this.props.product, qty: this.state.quantidade },
                cep: cep,
                cidade: cidade
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({
                        loading_cep: false,
                        msgErroCep: erro,
                    });
                }
                else {
                    let precos_prazos = [];
                    for (let i = 0; i < resp.precos_prazos.length; i++) {
                        precos_prazos.push({
                            final_shipping_cost: resp.precos_prazos[i].vltotal,
                            delivery_estimate_business_days: resp.precos_prazos[i].prazo,
                            retirada: resp.precos_prazos[i].retirada,
                            entrega_local: resp.precos_prazos[i].entrega_local,
                            volumes: resp.precos_prazos[i].packages ? resp.precos_prazos[i].packages : null,
                            agency_id: resp.precos_prazos[i].agency_id ? resp.precos_prazos[i].agency_id : null,
                            delivery_method_id: resp.precos_prazos[i].id ? resp.precos_prazos[i].id : Math.random(),
                            description: resp.precos_prazos[i].entrega_local == true ? 'Entrega local' : (resp.precos_prazos[i].retirada == true
                                ? 'Retirada no estabelecimento' : resp.precos_prazos[i].description),
                        })
                    }
                    let shipping = {};
                    if (precos_prazos.length != 0) {
                        shipping = precos_prazos[0];
                    }
                    this.setState({
                        loading_cep: false,
                        precos_prazos: precos_prazos,
                        volumes: resp.volumes,
                        shipping
                    });


                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading_cep: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading_cep: false
                });
            });
    }

    buscar_endereco(cep) {
        this.setState({
            loading_cep: true, msgErroCep: '', precos_prazos: [],
            shipping: {},
            volumes: [],
        });

        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.erro == 'true') {
                    this.setState({
                        msgErroCep: 'CEP incorreto',
                        loading_cep: false
                    }, () => {
                    });
                }
                else {
                    this.get_preco_prazo(cep, resp.localidade);
                }


                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading_cep: false, msgErroCep: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_cep: false, msgErroCep: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }


    salvar(id, quantidade) {
        if (this.props.token == undefined) {
            this.props.onAddToCartClick(this.props.product, quantidade)
            return;
        }
        this.setState({ loading: true, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/carrinho/salvar_item_carrinho/${id}/${quantidade}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro });
                }
                else {
                    this.props.onAddToCartClick(this.props.product, quantidade)

                    this.setState({ loading: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    render() {
        const { adClass = "", type = 'default', product, onAddToCartClick, onToggleWishlistClick, onAddToCompareClick } = this.props;
        let wish = false;
        for (let i = 0; i < this.props.wishlist.length; i++) {
            if (this.props.wishlist[i].id == product.id) {
                wish = true;
            }
        }
        return (
            <div className={`product-details ${adClass}`}>
                <h1 className="product-title">{product.name}</h1>
                {console.log('precos prazos', this.state.precos_prazos)}

                {product.avaliacao_total != null && <div className="ratings-container">
                    <div className="ratings">
                        <div className="ratings-val" style={{ width: parseInt((100 / 5) * product.avaliacao_total) + "%" }}></div>
                    </div>
                    {<Link className="ratings-text" to="#product-review-link" id="review-link">( {product.avaliacoes.length} Avaliações )</Link>}
                </div>}
                <div className="product-content">
                    <p style={{ whiteSpace: 'pre-line' }}>{product.descricao}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>

                    {0 == product.estoque ?
                        <div className="product-price">
                            <span className="out-price">{maskPriceDecimal(product.price)}</span>
                        </div> :

                        product.sale_price != null ?
                            <div className="product-price" style={{ display: 'block' }}>
                                <span className="old-price" style={{ fontSize: 16 }}>{maskPriceDecimal(product.price)}</span>
                                <br />
                                <span className="new-price" style={{ fontWeight: 'bold', color: '#19386e' }}>{maskPriceDecimal(product.sale_price)}</span>
                            </div> :
                            <div className="product-price" style={{ fontWeight: 'bold', color: '#19386e' }}>{maskPriceDecimal(product.price)}</div>
                    }
                    {0 != product.estoque && <div style={{ display: 'flex' }}>

                        <div className="btn-qty-custom-left" onClick={(e) => {
                            if (this.state.quantidade - 1 > 0) {
                                this.setState({ quantidade: this.state.quantidade - 1 });
                            }
                        }}>
                            -
                        </div>
                        <div className="input-qty-custom">
                            {this.state.quantidade}
                        </div>
                        <div className="btn-qty-custom-right" onClick={(e) => {
                            if (this.state.quantidade + 1 <= 10 && this.state.quantidade < parseInt(product.estoque)) {
                                this.setState({ quantidade: this.state.quantidade + 1 });
                            }
                        }}>
                            +
                        </div>
                    </div>}

                </div>

                {/* <div className="details-filter-row details-row-size">
                    <label htmlFor="qty">Qtd:</label>
                    <div className="product-details-quantity">
                        {0 != product.estoque && <select id="qty" style={{ paddingLeft: '40%' }} className="form-control" value={this.state.quantidade} onChange={(e) => {
                            this.setState({ quantidade: e.target.value });
                        }}>

                            {(() => {
                                let elements = [];
                                for (let i = 1; i <= product.estoque && i <= 10; i++) {
                                    elements.push(<option key={i} value={i}>{i}</option>)
                                }
                                return elements;
                            })()}


                        </select>}
                        {0 == product.estoque && <select id="qty" style={{ paddingLeft: '40%' }} className="form-control" defaultValue={this.state.quantidade}>

                            <option value="0">0</option>

                        </select>}
                    </div>
                </div> */}



                {/* {product.variants!=undefined ?
                    <div className="details-filter-row details-row-size">
                        <label>Color:</label>
                            {product.variants[0].rgb ? 
                            <div className="product-nav product-nav-dots">
                                {
                                    product.variants.map((vari, i) => 
                                            <Link to="#" key={ i} className={ 0 === i?'active':''} style={ {backgroundColor: vari.rgb} }>
                                            </Link>
                                    )
                                }
                            </div> :
                            <div className="product-nav product-nav-thumbs">
                                {
                                    product.variants[0].model ?
                                        product.variants.map((vari, i) => 
                                                <Link to="#" key={ i} className={ 0 === i?'active':'' }>
                                                <img src={ process.env.PUBLIC_URL + '/' + vari.model } alt="product desc" />
                                                </Link>
                                        ):
                                    product.variants[0].image ?
                                        product.variants.map((vari, i) => 
                                            <Link to="#" key={ i} className={ 0 === i?'active':'' }>
                                            <img src={ process.env.PUBLIC_URL + '/' + vari.image } alt="product desc" />
                                            </Link>
                                        ) : ''
                                }
                            </div>
                            }
                        </div> : '' 
                } */}
                {/* {product.tamanhos.length>0 && <div className="details-filter-row details-row-size" style={{display:'block'}}>
                    <label htmlFor="size">Tamanhos:</label>
                    <div className="select-custom">
                        <select name="size" id="size" className="form-control" defaultValue = "#">
                            <option value="#">Selecione um tamanho</option>
                            {product.tamanhos.map((item,id)=>(
                            <option key={id} value={item.id}>{item.name}</option>

                            ))}
                        </select>
                    </div>

                    {/* { 'default' === type ? 
                        <Link to="#" className="size-guide"><i className="icon-th-list"></i>size guide</Link> : 
                        '' } 
                </div>} */}


                <input id="qty" value={this.state.quantidade} hidden onChange={() => { }} />
                <div className="product-details-action" style={{ display: 'block' }}>
                    {product.estoque == 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{
                            textAlign: 'center', textAlign: 'center',
                            fontSize: '1.8rem',
                            fontWeight: 600
                        }}>Produto Indisponível</span>
                    </div>}

                    {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div>}
                    {this.state.loading == false && product.estoque != 0 && ((isIEBrowser() || isEdgeBrowser()) ?
                        <button className="btn-product btn-cart" style={{ maxWidth: 'none', width: '100%', marginBottom: 10 }} onClick={(e) => {
                            if (product.estoque > 0) {
                                e.preventDefault();
                                this.salvar(product.id, document.querySelector("#qty").value);
                            }
                        }} style={{ minHeight: "4rem" }}><span>Comprar</span></button>
                        :
                        <button className="btn-product btn-cart " style={{ maxWidth: 'none', width: '100%', marginBottom: 10 }} onClick={(e) => {
                            if (product.estoque > 0) {
                                e.preventDefault();
                                this.salvar(product.id, document.querySelector("#qty").value);
                            }
                        }}><span>Comprar</span></button>
                    )}
                    {this.state.loading_wish == false && (isIEBrowser() || isEdgeBrowser() ?
                        <div className="details-action-wrapper IE-detail-action-wrapper">
                            <button className={`btn-product btn-wishlist ${wish ? 'added-to-wishlist' : ''}  pl-0 pr-0`} title="Wishlist" onClick={() => this.salvar_wishlist(product)} style={{ minWidth: "127px" }}><span>{wish ? "Remover da lista de desejos" : "Adicionar na lista de desejos"}</span></button>
                            {/* <button className="btn-product btn-compare pr-0" title="Compare" onClick={ ()=> onAddToCompareClick(product) } style={ {minWidth: "157px"} }><span>Add to Compare</span></button> */}
                        </div> :
                        <div className="details-action-wrapper">
                            <button className={`btn-product btn-wishlist ${wish ? 'added-to-wishlist' : ''}  pl-0 pr-0`} title="Wishlist" onClick={() => this.salvar_wishlist(product)}><span>{wish ? "Remover da lista de desejos" : "Adicionar na lista de desejos"}</span></button>
                            {/* <button className="btn-product btn-compare pr-0" title="Compare" onClick={ ()=> onAddToCompareClick(product) }><span>Add to Compare</span></button> */}
                        </div>
                    )}
                    {this.state.loading_wish == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div>}
                </div>
                <div className="product-details-action" style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>

                        <label>Calcular frete *</label>
                        <div style={{ position: 'relative' }}>
                            <input value={this.state.cep} onChange={(e) => {
                                this.setState({ cep: maskCep(e.target.value) });
                                let text = e.target.value.replace('-', '');
                                if (text.length == 8) {
                                    this.buscar_endereco(text);
                                }
                            }} style={{ paddingRight: 80 }} type="text" placeholder="Cep do endereço de entrega" className="form-control" required />
                            <div style={{ position: 'absolute', top: 0, right: 0, background: 'white' }}>

                                {this.state.loading_cep == false && <button onClick={() => {
                                    let text = this.state.cep.replace('-', '');
                                    if (text.length == 8) {
                                        this.buscar_endereco(text);
                                    }
                                    else {
                                        this.setState({ msgErroCep: 'CEP incorreto' })
                                    }
                                }} style={{ width: 80, height: 40 }} type="button" className="btn btn-outline-primary btn-rounded">
                                    <span>Calcular</span>
                                    <i className="fas fa-truck"></i>
                                </button>}
                                {this.state.loading_cep == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: 80, height: 40 }}><div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>

                        </div>
                        <div style={{
                            color: '#721c24',
                            backgroundColor: '#f8d7da',
                            borderColor: '#f5c6cb'
                        }} hidden={this.state.msgErroCep == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErroCep: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErroCep}</p>

                        </div>
                        {/* <br /> */}

                        <div className=" mt-1" hidden={this.state.loading_cep == true || this.state.precos_prazos.length == 0}>
                            <h2 className="checkout-title" style={{ marginTop: 0 }}>Opções de entrega para a sua região:</h2>

                            {this.state.precos_prazos.map((item, id) => (
                                <div key={id} hidden={this.state.precos_prazos.length == 0} className="d-flex">
                                    <div>
                                        <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20 }}>
                                            <input type="radio"
                                                id={"shipping" + id}
                                                name="shipping"
                                                className="custom-control-input"
                                                onChange={(e) => {
                                                    console.log(item);
                                                    this.setState({ shipping: item });
                                                }}
                                                defaultChecked={this.state.shipping.delivery_method_id == item.delivery_method_id}
                                            />
                                            <label className="custom-control-label" htmlFor={"shipping" + id}>{item.description}</label>
                                        </div>
                                    </div>
                                    {item.retirada == true && <div><span style={{ color: 'green' }}>Grátis</span> - Disponível 1 hora após o pedido</div>}
                                    {item.retirada != true && <div>{item.final_shipping_cost == 0 ? <span style={{ color: 'green' }}>Grátis</span> : maskPriceDecimal(item.final_shipping_cost)} - {item.delivery_estimate_business_days}  {item.delivery_estimate_business_days == 1 ? 'dia útil' : 'dias úteis'}</div>}

                                </div>
                            ))}
                        </div>


                    </div>



                </div>

                <div className="product-details-footer">
                    <div className="product-cat">
                        <span>Categoria: </span>
                        {product.categorias.map((cat, index) => (
                            <span key={index} className="mr-0">
                                <Link to={"/shop/sidebar/list?cat_id=" + cat.id}>{cat.name}</Link>
                                {index < product.categorias.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>

                    <div className="social-icons social-icons-sm">
                        <span className="social-label">Compartilhar:</span>
                        <FacebookShareButton url={`${URL_FRONT}product/default/${product.id}`}>
                            <div className="social-icon" title="Facebook" ><i className="icon-facebook-f"></i></div>
                        </FacebookShareButton>
                        {/* <Link to="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></Link>
                        <Link to="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></Link>
                        <Link to="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></Link> */}
                    </div>
                </div>

                {this.props.children}
            </div>
        )
    }
}



export const mapStateToProps = (state, ownprops) => {

    return {
        token: state.user.token,
        wishlist: state.wishlist.list,

    };
}

export default connect(mapStateToProps, { replaceCart })(ProductDetailOne);