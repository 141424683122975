import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import Modal from 'react-modal';

import embalagem_default from '../../../../imgs/embalagem_default.jpg'
import amex from '../../../../imgs/cartoes/amex.png';
import elo from '../../../../imgs/cartoes/elo.png';
import hypercard from '../../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../../imgs/cartoes/mastercard.png';
import visa from '../../../../imgs/cartoes/visa.png';
import jcb from '../../../../imgs/cartoes/jcb.png';
import aura from '../../../../imgs/cartoes/aura.png';
import diners_club from '../../../../imgs/cartoes/diners_club.png';
import discover from '../../../../imgs/cartoes/discover.png';
import Stepper from 'react-stepper-horizontal';
import { maskPrice, maskPriceDecimal } from '../../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Board from '../ListDrag';
import reorder, { reorderQuoteMap } from '../ListDrag/reorder';
import { Shipment } from '../../../../config/Shipment';
import { createShipmentCheckout, createShipmentLabel } from '../../../../api/shipment';
import CircularProgress from '../../../common/loaders/CircularProgress';
// import { MdInfo } from "react-icons/md";
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const customStyles = {
    content: {
        right: 0,
        left: 0
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    },
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});



class PedidosAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pedidos: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            msgErroResposta: '',
            msgErroPedido: '',

            user_to_delete: '',
            modal_open: false,
            loading_save: false,

            pedido: { id: '' },
            termo: '',
            loading_pedido: false,
            loading_posted: false,
            show_warning_cancelar: false,
            show_warning_posted: false,
            show_warning_enviar: false,
            show_warning_entregue: false,
            embalagens: [],
            embalagem_select: '',
            show_warning_retirado: false,
            show_warning_pronto_retirada: false,
            new_label: '',
        };
    }


    onDragEnd = result => {

        //   if (result.combine) {
        //     if (result.type === "COLUMN") {
        //       const shallow = [...this.state.ordered];
        //       shallow.splice(result.source.index, 1);
        //       this.setState({ ordered: shallow });
        //       return;
        //     }

        //     const column = this.state.columns[result.source.droppableId];
        //     const withQuoteRemoved = [...column];
        //     withQuoteRemoved.splice(result.source.index, 1);
        //     const columns = {
        //       ...this.state.columns,
        //       [result.source.droppableId]: withQuoteRemoved
        //     };
        //     this.setState({ columns });
        //     return;
        //   }

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        // did not move anywhere - can bail early

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }
        // reordering column
        let pedido = { ...this.state.pedido };

        if (result.type === "COLUMN") {
            const ordered = reorder(
                this.state.pedido.embalagens,
                source.index,
                destination.index
            );
            pedido.embalagens = ordered;
            this.setState({
                pedido
            });

            return;
        }


        const data = reorderQuoteMap({
            quoteMap: [...pedido.embalagens],
            source,
            destination
        });

        pedido.embalagens = data.quoteMap;

        this.setState({
            pedido: pedido
        });
    };

    change_status(status) {
        this.setState({ loading_pedido: true, msgErroPedido: '', show_warning_entregue: false, show_warning_pronto_retirada: false, show_warning_retirado: false });
        let form = new FormData();
        form.append('pedido_id', this.state.pedido.id);
        form.append('status', status);


        fetch(`${URL}api/admin/change_status/${this.state.pedido.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_pedido: false, msgErroPedido: erro });
                }
                else {
                    this.setState({ loading_pedido: false, pedido: resp.pedido, page: 1 });
                    this.get_pedidos(1, this.state.termo);
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    cancelar_pedido() {
        this.setState({ loading_pedido: true, msgErroPedido: '', show_warning_cancelar: false });
        let form = new FormData();
        form.append('pedido_id', this.state.pedido.id);

        fetch(`${URL}api/admin/cancelar_pedido/${this.state.pedido.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_pedido: false, msgErroPedido: erro });
                }
                else {
                    this.setState({ loading_pedido: false, pedido: resp.pedido, page: 1 });
                    this.get_pedidos(1, this.state.termo);
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }





    incluir_pedido() {
        this.setState({ loading_pedido: true, msgErroPedido: '', show_warning_enviar: false });
        // let form = new FormData();
        // form.append('pedido_id', this.state.pedido.id);

        fetch(`${URL}api/admin/create_shipment`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                pedido_id: this.state.pedido.id,
                embalagens: this.state.pedido.embalagens
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_pedido: false, msgErroPedido: erro });
                }
                else if(responseLog.status!=500){
                    this.setState({ loading_pedido: false, pedido: resp.pedido, page: 1 });
                    this.get_pedidos(1, this.state.termo);
                }
                else {
                    this.setState({ loading_pedido: false, msgErroPedido:'Erro ao incluir pedido' });
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }




    get_pedidos(page = 1, termo) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_pedidos?page=${page}&search=${termo}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        this.setState({
                            pedidos: resp.pedidos,
                            embalagens: resp.embalagens,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_pedidos(this.state.page, this.state.termo);
    }

    checkStep(status) {
        if (status === 'Recebemos seu Pedido') {
            return 0;
        }
        else if (status === 'O seu pagamento foi aprovado!') {
            return 1;
        }
        else if (status === 'Sua Nota Fiscal foi emitida') {
            return 2;
        }
        else if (status === 'Despachado') {
            return 3;
        }
        else if (status === 'Pronto para envio') {
            return 4;
        }
        else if (status === 'Em trânsito') {
            return 5;
        }
        else if (status === 'Saiu para Entrega') {
            return 6;
        }
        else if (status === 'Entregue') {
            return 7;
        }
    }

    checkStepRetirada(status) {
        if (status === 'Recebemos seu Pedido') {
            return 0;
        }
        else if (status === 'O seu pagamento foi aprovado!') {
            return 1;
        }
        else if (status === 'Pronto para retirada') {
            return 2;
        }
        else if (status === 'Retirado') {
            return 3;
        }
    }

    checkStepExternal(pedido) {
        const { status, external_shipment } = pedido
        const shipmentStatus = Shipment.getLowerStatus([pedido])

        if (shipmentStatus === Shipment.not_delivered) {
            const deliveredDate = external_shipment.delivered_at
            this.setState({ shipmentNotDelivered: deliveredDate ? deliveredDate : true })
        }

        if (shipmentStatus === Shipment.cancelled) {
            this.setState({ shipmentError: true })
            return 0
        }

        if(shipmentStatus)
        if (status === 'Recebemos seu Pedido') {
            return 0
        }
        else if(status=='O seu pagamento foi aprovado!'){
            return 1;
        }
        else if(status=='Sua Nota Fiscal foi emitida'){
            return 2;
        }
        else{
            return shipmentStatus;
        }
        console.log(shipmentStatus,status)

        // if (shipmentStatus === Shipment.not_delivered) {
        //     const deliveredDate = external_shipment.delivered_at
        //     this.setState({ shipmentNotDelivered: deliveredDate ? deliveredDate : true })
        // }

        // if (status === 'Recebemos seu Pedido') {
        //     return 0
        // }

        // if (shipmentStatus === Shipment.cancelled) {
        //     this.setState({ shipmentError: true })
        //     return 0
        // }

        // if (status === 'O seu pagamento foi aprovado!' || status === 'Sua Nota Fiscal foi emitida') {
        //     return shipmentStatus
        // }
    }


    checkStepLocal(status) {
        if (status === 'Recebemos seu Pedido') {
            return 0;
        }
        else if (status === 'O seu pagamento foi aprovado!') {
            return 1;
        }
        // else if (status === 'Sua Nota Fiscal foi emitida') {
        //     return 2;
        // }
        else if (status === 'ENTREGUE') {
            return 2;
        }
    }

    getExternalStatus = (pedido) => {
        if(pedido.retirada==true || pedido.entrega.entrega_local==true){
            return pedido.status;
        }
        let shipments = pedido.external_shipment
        const statusIndex = Shipment.getLowerStatus([pedido])
        if(pedido.status=='Recebemos seu Pedido' || pedido.status=='O seu pagamento foi aprovado!'  || pedido.status=='Sua Nota Fiscal foi emitida'){
            return pedido.status;
        }
        else{
            const stepperIndex = Shipment.stepperMapping[statusIndex]
            return stepperIndex.title
        }

    }

    async handleCreateCheckout(shipment) {
        this.setState({ loading_posted: true, show_warning_posted: false })
        console.log(shipment)
        const shipmentIds = Shipment.getPendingIds(shipment)
        console.log(shipmentIds)

        const response = await createShipmentCheckout(shipmentIds)

        if (response.error) {
            return this.setState({ msgErroPedido: response.message, loading_posted: false })
        }

        let pedido = { ...this.state.pedido }
        pedido.entrega.label_url = response.label_url

        this.setState({ pedido, new_label: response.label_url, loading_posted: false, msgErroPedido: '' })
    }

    async handleCreateLabel(shipment) {
        this.setState({ loading_posted: true, show_warning_posted: false })
        console.log(shipment)
        const shipmentIds = Shipment.getPendingIds(shipment)
        console.log(shipmentIds)

        const response = await createShipmentLabel(shipmentIds)

        if (response.error) {
            return this.setState({ msgErroPedido: response.message, loading_posted: false })
        }

        let pedido = { ...this.state.pedido }
        pedido.entrega.label_url = response.label_url

        this.setState({ pedido, new_label: response.label_url, loading_posted: false, msgErroPedido: '' })
    }

    render() {
        let pedidos = this.state.pedidos;

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12 col-md-6">
                        <input placeholder="Pesquise pelo Nome do Usuário" className="form-control" value={this.state.termo} onChange={(e) => {
                            this.setState({ termo: e.target.value }, () => {
                                this.get_pedidos(this.state.page, this.state.termo);
                            });

                        }} />
                    </div>
                </div>
                <table className="table table-cart table-mobile">

                    <thead>
                        <tr>
                            <th>Pedido</th>
                            <th>Usuário</th>
                            <th>Nº Produtos</th>
                            <th>Valor Total</th>
                            <th>Status</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {pedidos.length > 0 ?
                            pedidos.map((item, index) =>
                                <tr key={index}>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                #{item.id}
                                            </h3>
                                        </div>
                                    </td>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.user.name}
                                            </h3>
                                        </div>
                                    </td>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.num_produtos}
                                            </h3>
                                        </div>
                                    </td>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {
                                                    maskPriceDecimal(item.total)

                                                }
                                            </h3>
                                        </div>
                                    </td>

                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {!item.external_shipment
                                                    ? item.status
                                                    : this.getExternalStatus(item)
                                                }
                                            </h3>
                                        </div>
                                    </td>

                                    <td style={{ width: 100 }}>
                                        <ReactTooltip />

                                        <button data-tip="Visualizar Pedido" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} onClick={() => {
                                            this.setState({ modal_open: true, pedido: item });
                                        }}
                                            className="destroy_it btn btn-success"><i
                                                className="fas fa-eye"></i></button>

                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há pedidos no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_pedidos(page, this.state.termo);
                }} />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.setState({ show_warning_cancelar: false });
                        this.cancelar_pedido();
                    }}
                    onCancel={() => {
                        this.setState({ show_warning_cancelar: false });
                    }}
                    show={this.state.show_warning_cancelar}
                    confirmBtnText='Sim, desejo cancelar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    ao cancelar este pedido ele não será mais entregue no endereço solicitado
                </SweetAlert>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.setState({ show_warning_cancelar: false });
                        this.incluir_pedido();
                    }}
                    onCancel={() => {
                        this.setState({ show_warning_enviar: false });
                    }}
                    show={this.state.show_warning_enviar}
                    confirmBtnText='Sim, desejo incluir'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    Deseja incluir o pedido na transportadora {this.state.pedido.entrega != undefined ? this.state.pedido.entrega.description : ''}?
                </SweetAlert>


                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.incluir_pedido();
                        this.change_status('ENTREGUE');

                    }}
                    onCancel={() => {
                        this.setState({ show_warning_entregue: false });
                    }}
                    show={this.state.show_warning_entregue}
                    confirmBtnText='Sim, desejo marcar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    Deseja marcar este pedido como entregue?
                </SweetAlert>


                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.setState({ show_warning_cancelar: false });
                        // this.incluir_pedido();
                        this.change_status('Pronto para retirada');

                    }}
                    onCancel={() => {
                        this.setState({ show_warning_pronto_retirada: false });
                    }}
                    show={this.state.show_warning_pronto_retirada}
                    confirmBtnText='Sim, desejo marcar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    Deseja marcar este pedido como pronto para retirada?
                </SweetAlert>


                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.setState({ show_warning_cancelar: false });
                        // this.incluir_pedido();
                        this.change_status('Retirado');

                    }}
                    onCancel={() => {
                        this.setState({ show_warning_retirado: false });
                    }}
                    show={this.state.show_warning_retirado}
                    confirmBtnText='Sim, desejo marcar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    Deseja marcar este pedido como retirado?
                </SweetAlert>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    ao deletar este pedido ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>

                {/* ------------------------Object post alerts------------------------ */}
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.handleCreateCheckout(this.state.pedido.external_shipment);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning_posted: false });
                    }}
                    show={this.state.show_warning_posted}
                    confirmBtnText='Sim, desejo marcar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    Deseja marcar este pedido como postado para transporte?
                </SweetAlert>

                <SweetAlert
                    success
                    title={"Etiqueta gerada com sucesso"}
                    onConfirm={() => {
                        this.setState({ new_label: '' });
                    }}
                    show={Boolean(this.state.new_label)}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="success"
                    confirmBtnStyle={{ color: 'white' }}
                >
                    <span>
                        Clique no link para impressão
                        <br />
                        <a href={this.state.new_label} target="_blank">{this.state.new_label}</a>
                    </span>
                </SweetAlert>

                <Modal
                    isOpen={this.state.modal_open}
                    onRequestClose={() => this.setState({ modal_open: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div>
                    {this.state.pedido.id != '' && <div>

                        <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                            <h4 style={{ textAlign: 'center' }}>Pedido #{this.state.pedido.id}</h4>
                        </div>
                        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                            <div className="row">
                                <div className="col-12" style={{ overflowX: 'auto' }}>
                                    {/* {this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Sua Nota Fiscal foi emitida' }, { title: 'Despachado' }, { title: 'Pronto para envio' }, { title: 'Em trânsito' }, { title: 'Saiu para Entrega' }, { title: 'Entregue' }]}
                                            activeStep={this.checkStep(this.state.pedido.status)}
                                        />
                                    </div>} */}
                                    {this.state.pedido.entrega!=undefined && this.state.pedido.entrega.entrega_local == false && this.state.pedido.retirada ==false && this.state.pedido.external_shipment && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={Shipment.stepperMapping}
                                            activeStep={this.checkStepExternal(this.state.pedido)}
                                        />
                                    </div>}

                                    {this.state.pedido.entrega != undefined && this.state.pedido.entrega.entrega_local == true && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Entregue' }]}
                                            activeStep={this.checkStepLocal(this.state.pedido.status)}
                                        />
                                    </div>}

                                    {this.state.pedido.retirada == true && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Pronto para retirada' }, { title: 'Retirado' }]}
                                            activeStep={this.checkStepRetirada(this.state.pedido.status)}
                                        />
                                    </div>}
                                    {this.state.pedido.status == 'Pedido cancelado' && <div>
                                        <h4 style={{ color: 'red', textAlign: 'center', marginTop: '1.4rem' }}>Pedido Cancelado</h4>
                                    </div>}
                                    <hr />
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2" >
                                    <p style={{ color: 'black' }}><span style={{ fontWeight: 'bold' }}>Usuário:<br /></span> {this.state.pedido.user.name}</p>
                                </div>

                                {this.state.pedido.transacao != null && <div className="col-12 col-sm-6 col-md-4  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Forma de Pagamento:</p>
                                    <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                        <div className="d-flex">

                                            <div className="d-flex align-items-center">

                                                <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('DINERS') ? 'block' : 'none' }} />
                                                <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('DISCOVER') ? 'block' : 'none' }} />
                                                <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('JCB') ? 'block' : 'none' }} />
                                                <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('AURA') ? 'block' : 'none' }} />
                                            </div>
                                            <div className="align-items-center" style={{ marginLeft: 0 }}>

                                                <p style={{ color: 'black', marginBottom: '1rem' }}><b style={{ marginRight: 10, fontWeight: 500 }}>(Crédito)</b> {this.state.pedido.transacao.credit_card.brand.toUpperCase()} </p>

                                                <p style={{ margin: 0, color: 'black' }}> •••• {this.state.pedido.transacao.credit_card.last_digits}</p>

                                            </div>
                                        </div>

                                    </h3>
                                    <p style={{ color: 'black', fontWeight: 'bold' }}>Status:
                                        {/* {this.state.pedido.transacao.status == 'paid' && <span style={{ color: 'green', fontWeight: 'normal' }}> Pago</span>}
                                        {this.state.pedido.transacao.status == 'authorized' && <span style={{ color: 'green', fontWeight: 'normal' }}> Autorizado</span>}
                                        {this.state.pedido.transacao.status == 'processing' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Processando</span>}
                                        {this.state.pedido.transacao.status == 'refunded' && <span style={{ color: 'red', fontWeight: 'normal' }}> Extornado</span>}
                                        {this.state.pedido.transacao.status == 'pending_refund' && <span style={{ color: 'red', fontWeight: 'normal' }}> Extorno pendente</span>}
                                        {this.state.pedido.transacao.status == 'refused' && <span style={{ color: 'red', fontWeight: 'normal' }}> Recursado</span>}
                                        {this.state.pedido.transacao.status == 'waiting_payment' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Aguardando pagamento</span>} */}

                                        {(this.state.pedido.transacao.status == '2' || this.state.pedido.transacao.status == '4' || this.state.pedido.transacao.status == '6') && <span style={{ color: 'green', fontWeight: 'normal' }}> Autorizado</span>}
                                        {(this.state.pedido.transacao.status == '05' || this.state.pedido.transacao.status == '99') && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Não autorizada</span>}
                                        {this.state.pedido.transacao.status == '57' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão expirado</span>}
                                        {this.state.pedido.transacao.status == '78' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão bloqueado</span>}
                                        {this.state.pedido.transacao.status == '77' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão cancelado</span>}
                                        {this.state.pedido.transacao.status == '70' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Problemas com o cartão de crédito</span>}
                                    </p>
                                </div>}

                                {this.state.pedido.entrega != null && <div className="col-12 col-sm-6 col-md-4  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Forma de Entrega:
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}>{this.state.pedido.entrega.description} - {maskPriceDecimal(parseFloat(this.state.pedido.entrega.final_shipping_cost))}</span>
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}>Previsão: {this.state.pedido.entrega.delivery_estimate_business_days} dias úteis</span>
                                        <br />
                                        {this.state.pedido.entrega.label_url &&
                                            <>
                                                <a href={this.state.pedido.entrega.label_url} target="_blank">
                                                    Clique aqui para visualização da etiqueta
                                                </a>
                                                <br />
                                            </>}
                                        {this.state.pedido.external_shipment && Shipment.getTrackingCode(this.state.pedido.external_shipment) &&
                                            <>
                                                <p style={{ fontWeight: 'normal', marginTop: 0, color: 'black' }}>Código de rastreio: {Shipment.getTrackingCode(this.state.pedido.external_shipment)}</p>
                                                <a href={`https://app.melhorrastreio.com.br/app/melhorenvio/${Shipment.getTrackingCode(this.state.pedido.external_shipment)}`} target="_blank">
                                                    Rastrear pedido
                                                </a>
                                                <br />
                                            </>}
                                        {this.state.shipmentNotDelivered &&
                                            <span style={{ fontWeight: 'normal', color: '#D72605' }}>Tentativa de entrega sem sucesso
                                                {typeof this.state.shipmentNotDelivered !== 'boolean' && ` - ${this.state.shipmentNotDelivered}`}</span>}
                                    </p>
                                </div>}
                                {this.state.pedido.retirada == false && <div className="col-12 col-sm-6 col-md-4  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Endereço de Entrega:</p>

                                    <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black', marginBottom: 10 }}>
                                        {this.state.pedido.endereco.rua}, {this.state.pedido.endereco.numero}
                                        <br />
                                        {this.state.pedido.endereco.bairro}, {this.state.pedido.endereco.cidade} - {this.state.pedido.endereco.estado}
                                        <br />
                                        Cep: {this.state.pedido.endereco.cep}
                                        <br />
                                        {this.state.pedido.endereco.complemento != null && this.state.pedido.endereco.complemento != '' && <span>Complemento: {this.state.pedido.endereco.complemento}</span>}
                                    </p>
                                </div>}
                                {<div className="col-12 col-sm-6 col-md-4  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Endereço de Cobrança:</p>

                                    <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black', marginBottom: 10 }}>
                                        {this.state.pedido.endereco_cobranca.rua}, {this.state.pedido.endereco_cobranca.numero}
                                        <br />
                                        {this.state.pedido.endereco_cobranca.bairro}, {this.state.pedido.endereco_cobranca.cidade} - {this.state.pedido.endereco_cobranca.estado}
                                        <br />
                                        Cep: {this.state.pedido.endereco_cobranca.cep}
                                        <br />
                                        {this.state.pedido.endereco_cobranca.complemento != null && this.state.pedido.endereco_cobranca.complemento != '' && <span>Complemento: {this.state.pedido.endereco_cobranca.complemento}</span>}
                                    </p>
                                </div>}
                            </div>


                        </div>
                        {this.state.pedido.retirada == false && this.state.pedido.entrega.entrega_local == false && <div>

                            {this.state.pedido.entrega.codigo != null &&
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-md-12  mt-2" >
                                        <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Dados do pedido:</p>

                                        <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black' }}>
                                            Código: {this.state.pedido.entrega.codigo}
                                        </p>
                                        <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black', marginBottom: 10 }}>
                                            ShipmentId: {this.state.pedido.entrega.shipmentId}
                                        </p>

                                    </div>
                                </div>


                            }
                            <hr />

                            <h2 className="checkout-title text-center" >Embalagens que devem ser utilizadas:</h2>
                            <div className="row justify-content-end" style={{ marginLeft: 0, marginRight: 0 }}>
                                <div style={{ marginRight: 10 }}>

                                    <div className="select-custom">
                                        <select name="embalagens" id="embalagens" className="form-control" value={this.state.embalagem_select} onChange={(e) => {
                                            this.setState({ embalagem_select: e.target.value });
                                        }}>
                                            <option value="">Selecione uma embalagem</option>
                                            {this.state.embalagens.map((item, id) => (
                                                <option key={id} value={id}>{item.nome}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        disabled={this.state.embalagem_select == ''}
                                        onClick={() => {
                                            let embalagem = this.state.embalagens.filter((item, id) => id == this.state.embalagem_select)[0];
                                            embalagem = { ...embalagem };
                                            embalagem.id = uuidv4();
                                            embalagem.quantidade_items = 0;
                                            embalagem.produtos = [];
                                            embalagem.embalagem_produto = false;
                                            let pedido = { ...this.state.pedido };
                                            pedido.embalagens.push(embalagem);
                                            this.setState({ pedido });
                                        }}
                                        className="btn btn-outline-primary btn-rounded" style={{ height: '40px', cursor: 'pointer' }}><span>Adicionar embalagem</span><i className="icon-plus"></i></button>
                                </div>
                            </div>
                            <Board removeColum={(index) => {
                                let pedido = { ...this.state.pedido };
                                pedido.embalagens = pedido.embalagens.filter((item, id) => id != index);
                                this.setState({ pedido });
                            }} embalagens={this.state.pedido.embalagens} onDragEnd={(r) => this.onDragEnd(r)} />
                            {/* <div className="row">

                            {this.state.pedido.embalagens.map((item, id) => {
                                let produtos = {};
                                for (let i = 0; i < item.produtos.length; i++) {
                                    if (produtos[item.produtos[i].produto_id] == undefined) {
                                        produtos[item.produtos[i].produto_id] = item.produtos[i];
                                        produtos[item.produtos[i].produto_id].quantidade = 1;
                                    }
                                    else {
                                        produtos[item.produtos[i].produto_id].quantidade += 1;
                                    }

                                }
                                return (
                                    <div key={id} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                        <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 5, textAlign: 'center' }}>{item.nome} {item.embalagem_produto == true ? '(Embalagem do próprio produto)' : ''}</p>

                                        <img style={{ width: '100%', height: 150, objectFit: 'contain', paddingRight: 10 }} src={embalagem_default} alt="Product" />
                                        <div className="mt-2">
                                            <p style={{ color: 'black', textAlign: 'center' }}>Comprimento: {item.comprimento} cm</p>
                                            <p style={{ color: 'black', textAlign: 'center' }}>Largura: {item.largura} cm</p>
                                            <p style={{ color: 'black', textAlign: 'center' }}>Altura: {item.altura} cm</p>
                                            <p style={{ color: 'black', marginBottom: 5, textAlign: 'center' }}>Quantidade de itens: {item.quantidade_items}</p>

                                            {item.etiqueta != null && <a href={item.etiqueta} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Imprimir Etiqueta</a>}

                                       </div>

                                    </div>

                                    
                                )
                            })}
                        </div> */}
                            {<p className="d-md-flex d-none" style={{ marginBottom: 10, textAlign: 'justify', color: 'red' }}>{this.state.msgErroPedido}</p>}
                            {<p className="d-md-none d-flex" style={{ marginBottom: 10, textAlign: 'center', color: 'red' }}>{this.state.msgErroPedido}</p>}
                            {this.state.pedido.external_shipment && Shipment.isPostable(this.state.pedido.external_shipment) &&
                                this.state.pedido.status=='pending' && !this.state.pedido.entrega.label_url &&
                                <>
                                <div className="summary-total d-md-flex d-none ms-2" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>
                                    {!this.state.loading_posted ?
                                        <button type="button" style={{ width: 200 }} onClick={() => this.setState({ show_warning_posted: true })} className="btn btn-outline-info btn-order btn-block mx-2">
                                            <span className="btn-text">Gerar Etiqueta e Efetuar Checkout</span>
                                            <span className="btn-hover-text">Gerar Etiqueta e Efetuar Checkout</span>
                                        </button>
                                        : <CircularProgress className='bg-info' width={200} />}
                                </div>
                                </>
                            }
                            {(this.state.pedido.status == 'Sua Nota Fiscal foi emitida') && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                                {this.state.loading_pedido == false && <button type="button" style={{ width: 200 }} onClick={() => {
                                    this.setState({ show_warning_enviar: true });
                                }}
                                    className="btn btn-outline-success btn-order btn-block mx-2">

                                    <span className="btn-text">Salvar embalagens e incluir pedido</span>
                                    <span className="btn-hover-text">Salvar embalagens e incluir pedido</span>
                                </button>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}

                            {(this.state.pedido.status == 'Sua Nota Fiscal foi emitida') && <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                                {this.state.loading_pedido == false && <button type="button" onClick={() => {
                                    this.setState({ show_warning_enviar: true });
                                }}
                                    className="btn btn-outline-success btn-order btn-block mx-2">

                                    <span className="btn-text">Salvar embalagens e incluir pedido</span>
                                    <span className="btn-hover-text">Salvar embalagens e incluir pedido</span>
                                </button>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}
                        </div>}


                        {(this.state.pedido.status == 'O seu pagamento foi aprovado!') && this.state.pedido.entrega != undefined && this.state.pedido.entrega.entrega_local == true && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                            {this.state.loading_pedido == false && <button type="button" style={{ width: 200 }} onClick={() => {
                                this.setState({ show_warning_entregue: true });
                            }}
                                className="btn btn-outline-success btn-order btn-block mx-2">

                                <span className="btn-text">Marcar como entregue</span>
                                <span className="btn-hover-text">Marcar como entregue</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                        {(this.state.pedido.status == 'O seu pagamento foi aprovado!') && this.state.pedido.entrega != undefined && this.state.pedido.entrega.entrega_local == true && <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                            {this.state.loading_pedido == false && <button type="button" onClick={() => {
                                this.setState({ show_warning_entregue: true });
                            }}
                                className="btn btn-outline-success btn-order btn-block mx-2">

                                <span className="btn-text">Marcar como entregue</span>
                                <span className="btn-hover-text">Marcar como entregue</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                        <ReactTooltip />

                        {/* <div className='row justify-content-end'> */}
                            {(this.state.pedido.status == 'O seu pagamento foi aprovado!' || this.state.pedido.status == 'Recebemos seu Pedido') && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                                {this.state.loading_pedido == false &&
                                    <>
                                        <button
                                            disabled={(this.state.pedido.external_shipment.length>0)
                                                ? Shipment.isCancellable(this.state.pedido.external_shipment)
                                                : false}
                                            type="button"
                                            style={{ width: 200 }}
                                            onClick={() => this.setState({ show_warning_cancelar: true })}
                                            className="btn btn-outline-danger btn-order btn-block mx-2">

                                            <span className="btn-text">Cancelar Pedido</span>
                                            <span className="btn-hover-text">Cancelar Pedido</span>
                                        </button>

                                        {this.state.pedido.external_shipment
                                            && !Shipment.isCancellable(this.state.pedido.external_shipment) &&
                                            <div className='ms-2' style={{ cursor: 'pointer' }}>
                                                {/* <MdInfo data-tip="Transportadora já notificada sobre envio dos produtos" size={25} /> */}
                                            </div>
                                        }
                                    </>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}

                            {(this.state.pedido.status == 'O seu pagamento foi aprovado!' || this.state.pedido.status == 'Recebemos seu Pedido') && <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                                {this.state.loading_pedido == false &&
                                    <>
                                        <button
                                            disabled={this.state.pedido.external_shipment.length>0
                                                ? Shipment.isCancellable(this.state.pedido.external_shipment)
                                                : false}
                                            type="button"
                                            onClick={() => this.setState({ show_warning_cancelar: true })}
                                            className="btn btn-outline-danger btn-order btn-block mx-2">

                                            <span className="btn-text">Cancelar Pedido</span>
                                            <span className="btn-hover-text">Cancelar Pedido</span>
                                        </button>

                                        {this.state.pedido.external_shipment
                                            && !Shipment.isCancellable(this.state.pedido.external_shipment) &&
                                            <div className='ms-2' style={{ cursor: 'pointer' }}>
                                                {/* <MdInfo data-tip="Transportadora já notificada sobre envio dos produtos" size={25} /> */}
                                            </div>
                                        }
                                    </>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}

                            {/* ------------------------Object post button------------------------ */}
                            

                            {(this.state.pedido.status == 'O seu pagamento foi aprovado!') && this.state.pedido.retirada == true && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                                {this.state.loading_pedido == false && <button type="button" style={{ width: 200 }} onClick={() => {
                                    this.setState({ show_warning_pronto_retirada: true });
                                }}
                                    className="btn btn-outline-success btn-order btn-block mx-2">

                                    <span className="btn-text">Pronto para retirada</span>
                                    <span className="btn-hover-text">Pronto para retirada</span>
                                </button>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}

                            {(this.state.pedido.status == 'O seu pagamento foi aprovado!') && this.state.pedido.retirada == true && <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                                {this.state.loading_pedido == false && <button type="button" onClick={() => {
                                    this.setState({ show_warning_pronto_retirada: true });
                                }}
                                    className="btn btn-outline-success btn-order btn-block mx-2">

                                    <span className="btn-text">Pronto para retirada</span>
                                    <span className="btn-hover-text">Pronto para retirada</span>
                                </button>}
                                {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                            </div>}






                        {/* </div> */}
                        {(this.state.pedido.status == 'Pronto para retirada') && this.state.pedido.retirada == true && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                            {this.state.loading_pedido == false && <button type="button" style={{ width: 200 }} onClick={() => {
                                this.setState({ show_warning_retirado: true });
                            }}
                                className="btn btn-outline-success btn-order btn-block mx-2">

                                <span className="btn-text">Retirado</span>
                                <span className="btn-hover-text">Retirado</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                        {(this.state.pedido.status == 'Pronto para retirada') && this.state.pedido.retirada == true && <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                            {this.state.loading_pedido == false && <button type="button" onClick={() => {
                                this.setState({ show_warning_retirado: true });
                            }}
                                className="btn btn-outline-success btn-order btn-block mx-2">

                                <span className="btn-text">Retirado</span>
                                <span className="btn-hover-text">Retirado</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                        <hr />
                        <h2 className="checkout-title" >Resumo dos produtos:</h2>
                        <table className="table table-cart table-mobile">

                            <thead>
                                <tr>
                                    <th>Foto</th>

                                    <th>Produto</th>
                                    <th>Quantidade</th>
                                    <th>Valor Individual</th>

                                    <th>Valor Total</th>

                                    {/* <th>Ações</th> */}
                                </tr>
                            </thead>
                            {this.state.loading == false && <tbody>
                                {this.state.pedido.produtos.map((item, index) =>
                                    <tr key={index}>
                                        <td className="product-col">
                                            <div className="product">
                                                <img style={{ width: 100, height: 100, objectFit: 'contain', paddingRight: 10 }} src={URL + item.imagem} alt="Product" />

                                            </div>
                                        </td>

                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">


                                                    {item.name}
                                                </h3>
                                            </div>

                                        </td>

                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {item.quantidade}
                                                </h3>
                                            </div>
                                        </td>
                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {maskPriceDecimal(0 < item.sale_price ?

                                                        item.sale_price :

                                                        item.price

                                                    )}
                                                </h3>
                                            </div>
                                        </td>

                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {maskPriceDecimal(0 < item.sale_price ?

                                                        (item.sale_price * item.quantidade) :

                                                        (item.price * item.quantidade)

                                                    )}
                                                </h3>
                                            </div>
                                        </td>

                                    </tr>)}

                            </tbody>}



                            {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div></td></tr></tbody>}
                        </table>
                        <div className="summary-subtotal" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>Subtotal:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.total) - (this.state.pedido.entrega != null ? parseFloat(this.state.pedido.entrega.final_shipping_cost) : 0))}</span>
                        </div>
                        {this.state.pedido.entrega != null && <div className="summary-subtotal" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>
                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>{this.state.pedido.entrega.description}:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.entrega.final_shipping_cost))}</span>
                        </div>}

                        <div className="summary-total" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>
                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>Total:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.total))}</span>
                        </div>

                        {/* {(this.state.pedido.status == 'O seu pagamento foi aprovado!' || this.state.pedido.status == 'Recebemos seu Pedido') &&<p className="d-md-flex d-none" style={{ marginBottom: 10, textAlign: 'justify', color: 'red' }}>{this.state.msgErroPedido}</p>}
                        {(this.state.pedido.status == 'O seu pagamento foi aprovado!' || this.state.pedido.status == 'Recebemos seu Pedido') &&<p className="d-md-none d-flex" style={{ marginBottom: 10, textAlign: 'center', color: 'red' }}>{this.state.msgErroPedido}</p>} */}


                    </div>}

                </Modal>
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(PedidosAdmin);