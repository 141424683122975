import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';
import SweetAlert from 'react-bootstrap-sweetalert';

class EditarToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            expires_in: 0,
            token_type: 'Bearer',
            access_token: '',
            refresh_token:'',
            loading_save: false,
            msgErro:'',
            redirect:false,
            roles:[],
            role_id:'',
            show_success:false
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/shipment/edit_token`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                code:this.state.code,
                expires_in:this.state.expires_in,
                token_type:this.state.token_type,
                access_token:this.state.access_token,
                refresh_token:this.state.refresh_token,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({top:0,behavior: 'smooth'})

                }
                else {

                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
    }

 

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/usuarios'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br/>
                
                <label>Code *</label>
                <input value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} type="text" placeholder="Code" className="form-control" required />
                {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                <label>Access Token *</label>
                <input value={this.state.access_token} onChange={(e) => this.setState({ access_token: e.target.value })} type="text" placeholder="Access Token" className="form-control" required />


                <label>Refresh Token *</label>
                <input value={this.state.refresh_token} onChange={(e) => this.setState({ refresh_token: e.target.value })} type="text" placeholder="Refresh Token" className="form-control" required />
              
                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}
                <SweetAlert
                        success
                        title={"Sucesso"}
                        onConfirm={() => {
                            this.setState({ show_success: false });
                        }}
                        show={this.state.show_success}
                        confirmBtnStyle={{ color: 'white' }}

                        confirmBtnText='OK'
                        confirmBtnBsStyle="primary"

                    >
                        Token editado com sucesso

                    </SweetAlert>

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(EditarToken);