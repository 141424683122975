import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';
import { changeState, logout, replaceCart, replaceWishList } from '../../../actions';
import { connect } from 'react-redux';

// import Custom Components

class Account extends Component {
    render() {
        console.log(this.props.user)
        return (
            <div>
                <p>Olá <span className="font-weight-normal text-dark">{this.props.user.name}</span> (não é <span className="font-weight-normal text-dark">{this.props.user.name}</span>? <a href='#' onClick={(e)=>{
                    e.preventDefault();
                    localStorage.removeItem('token');
                                                this.props.replaceCart([]);
                                                this.props.replaceWishList([]);
                                                this.props.changeState({ endereco: {},
                                                    precos_prazos: [],
                                                    volumes: [],
                                                
                                                    endereco_cobranca: {},
                                                    forma_pagamento: { type: '', object: {},installment:1  },
                                                    shipping: { delivery_method_id: '', final_shipping_cost: 0 }})
                                                this.props.logout();
                }}>Sair</a>)
                                                    <br />
                                                    </p>
                                                    {/* From your account dashboard you can view your <Link to="#tab-orders" className="tab-trigger-link link-underline">recent orders</Link>, manage your <Link to="#tab-address" className="tab-trigger-link">shipping and billing addresses</Link>, and <Link to="#tab-account" className="tab-trigger-link">edit your password and account details</Link>.</p> */}
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.user.token
})

export default connect( mapStateToProps,{logout, replaceCart, replaceWishList,changeState} )( Account );