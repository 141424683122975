import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import Accordion from '../../features/accordion/accordion';
import Card from '../../features/accordion/card';

import { getCartTotal } from '../../../services';
import { URL } from '../../../variables';
import { maskCep, maskPriceDecimal } from '../../../Auxiliar/Masks';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { logout, changeState } from '../../../actions/index';

import { isIEBrowser, isSafariBrowser, isEdgeBrowser } from '../../../utils/utils';

import Stepper from 'react-stepper-horizontal';
import moment from 'moment';

class SelecioneEntrega extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_cart: false,
            loading_save: false,
            msgErro: '',
            redirect: false,
            loading: true,
            precos_prazos: [],
            shipping: {},
            volumes: []
        }
    }
    componentDidMount() {
        // let item = document.querySelector("#checkout-discount-input");

        // item.addEventListener("focus", function (e) {
        //     e.currentTarget.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        // });

        // item.addEventListener("blur", function (e) {
        //     let $this = e.currentTarget;
        //     if ($this.value.length !== 0) {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        //     } else {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 1");
        //     }
        // });
        this.props.changeState({ shipping: { delivery_method_id: '', final_shipping_cost: 0 }, volumes: [], precos_prazos: [], forma_pagamento: { type: '', object: {}, installment: 1 } });


        if (this.props.cartlist.length == 0 || this.props.token == undefined || this.props.endereco == undefined || this.props.endereco.id == undefined) {
            this.setState({ redirect_cart: true });
        }
        else {
            this.get_shipment_prices(this.props.endereco.cep.replace('-', ''));
        }
    }

    get_shipment_prices(cep) {
        this.setState({ loading: true, msgErro: '' });
        let produtos = [...this.props.cartlist];
        console.log(produtos);
        fetch(`${URL}api/carrinho/get_shipment_prices`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                produtos: produtos,
                cep: cep,
                endereco_id: this.props.endereco.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    this.setState({
                        loading: false,
                    });
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({
                        loading: false,
                        msgErro: erro,
                    });
                }
                else {
                    let precos_prazos = [];
                    for (let i = 0; i < resp.precos_prazos.length; i++) {
                        precos_prazos.push({
                            final_shipping_cost: resp.precos_prazos[i].vltotal,
                            delivery_estimate_business_days: resp.precos_prazos[i].prazo,
                            retirada: resp.precos_prazos[i].retirada,
                            entrega_local: resp.precos_prazos[i].entrega_local,
                            volumes: resp.precos_prazos[i].packages ? resp.precos_prazos[i].packages : null,
                            agency_id: resp.precos_prazos[i].agency_id ? resp.precos_prazos[i].agency_id : null,
                            delivery_method_id: resp.precos_prazos[i].id ? resp.precos_prazos[i].id : Math.random(),
                            description: resp.precos_prazos[i].entrega_local == true ? 'Entrega local' : (resp.precos_prazos[i].retirada == true
                                ? 'Retirada no estabelecimento' : resp.precos_prazos[i].description),
                        })
                    }
                    let shipping = {};
                    if (precos_prazos.length != 0) {
                        shipping = precos_prazos[0];
                    }

                    this.setState({
                        loading: false,
                        precos_prazos: precos_prazos,
                        volumes: resp.volumes,
                        shipping
                    });


                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    componentDidUpdate(props) {
        if (this.props.cartlist.length != props.cartlist.length) {
            this.setState({ redirect_cart: true });
        }
    }



    render() {
        let hour = moment().format('HH:mm');

        const { cartlist, total } = this.props;
        const shippingPrice = { "free": 0, "standard": 10, "express": 20 };
        const shippingObj = { "free": "Free shipping", "standard": "Standard", "express": "Express" };
        let endereco = this.props.endereco;
        console.log(endereco);
        return (
            <div className="main">
                {this.state.redirect_cart == true && <Redirect to={'/shop/cart'} />}
                <PageHeader background="url(assets/images/cart.jpg)" title="Entrega" subTitle="Forma de Entrega" />
                <div className="d-block d-sm-none">

                    <Breadcrumb title="Opção de Entrega" parent1={["Carrinho", "shop/cart"]} parent2={["Endereço de Entrega", "shop/address"]} />
                </div>
                <div className="container d-none d-sm-block">

                    <div className={'breadcrumb-nav'}>
                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                            activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                            steps={[{
                                title: 'Carrinho',
                                href: '/shop/cart',
                            },
                            {
                                title: 'Endereço',
                                href: '/shop/address'
                            },
                            {
                                title: 'Entrega',
                            },
                            {
                                title: 'Pagamento',
                            },
                            {
                                title: 'Resumo'
                            },
                            ]} activeStep={2} />
                    </div>
                </div>
                <div className="page-content">
                    <div className="checkout">
                        <div className="container">
                            {/* <div className="checkout-discount">
                                <form action="#">
                                    <input type="text" className="form-control" required id="checkout-discount-input"/>
                                    <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <span>Click here to enter your code</span></label>
                                </form>
                            </div> */}


                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div style={{
                                            color: '#721c24',
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#f5c6cb'
                                        }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-12 col-sm-6 mt-1" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Enviar Para:</h2>
                                                {endereco != undefined && <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>}
                                            </div>

                                            <div className="col-12 col-sm-6 mt-1" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Escolha uma opção de entrega para a sua região:</h2>

                                                {this.state.precos_prazos.map((item, id) => (
                                                    <div key={id} hidden={this.state.precos_prazos.length == 0} className="d-flex">
                                                        <div>
                                                            <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20 }}>
                                                                <input type="radio"
                                                                    id={"shipping" + id}
                                                                    name="shipping"
                                                                    className="custom-control-input"
                                                                    onChange={(e) => {
                                                                        console.log('tetduagdijuasdfokaspfasfa', item);
                                                                        this.setState({ shipping: item });
                                                                    }}
                                                                    defaultChecked={this.state.shipping.delivery_method_id == item.delivery_method_id}
                                                                />
                                                                <label className="custom-control-label" htmlFor={"shipping" + id}>{item.description}</label>
                                                            </div>
                                                        </div>
                                                        {item.retirada == true && <div><span style={{ color: 'green' }}>Grátis</span> - {hour < '08:00' ? 'Disponível para retirada hoje a partir das 8h' : ((hour >= '08:00' && hour < '21:00') ? 'Disponível para retirada hoje' : 'Disponível para retirada amanhã a partir das 8h')}</div>}
                                                        {item.retirada != true && <div>{item.final_shipping_cost == 0 ? <span style={{ color: 'green' }}>Grátis</span> : maskPriceDecimal(item.final_shipping_cost)} - {item.delivery_estimate_business_days} {item.delivery_estimate_business_days == 1 ? 'dia útil' : 'dias úteis'}</div>}

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>}

                                    </div>

                                    <aside className="col-lg-3">
                                        <div className="summary">
                                            {/* <h3 className="summary-title">Enviar Para</h3> */}

                                            <h3 className="summary-title">Seu Pedido</h3>

                                            <table className="table table-summary">
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {cartlist.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{ color: 'black' }}><Link to="#">{item.qty}x {item.name}</Link></td>
                                                            <td style={{ color: 'black' }}>
                                                                {maskPriceDecimal(parseFloat(item.sale_price == null ? item.price : item.sale_price) * parseInt(item.qty))}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>{maskPriceDecimal(total)}</td>
                                                    </tr>

                                                    {this.state.shipping.delivery_method_id != undefined && <tr className="summary-subtotal">
                                                        <td>{this.state.shipping.description}:</td>
                                                        {/* <td>{maskPriceDecimal(this.state.shipping.final_shipping_cost)}</td> */}
                                                        <td>{this.state.shipping.final_shipping_cost == 0 ? <span style={{ color: 'green' }}>Grátis</span> : maskPriceDecimal(this.state.shipping.final_shipping_cost)}</td>

                                                    </tr>}

                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>{maskPriceDecimal(total + (this.state.shipping.delivery_method_id == undefined ? 0 : Number(this.state.shipping.final_shipping_cost)))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Link onClick={() => {
                                                // if (this.state.endereco_select.id != undefined) {
                                                //     this.props.changeState({ endereco: this.state.endereco_select });
                                                // }
                                                if (this.state.shipping.delivery_method_id != undefined) {
                                                    this.props.changeState({ shipping: this.state.shipping, volumes: this.state.volumes, precos_prazos: this.state.precos_prazos, forma_pagamento: { type: '', object: {}, installment: 1 } });
                                                }
                                            }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.state.shipping.delivery_method_id == undefined || this.state.precos_prazos.length == 0 || this.state.loading == true || this.props.endereco == undefined || this.props.endereco.id == undefined} to={`${process.env.PUBLIC_URL}/shop/payment`}
                                                className="btn btn-outline-primary-2 btn-order btn-block">

                                                <span className="btn-text">Continuar</span>
                                                <span className="btn-hover-text">Selecionar Forma de Pagamento</span>
                                            </Link>
                                        </div>
                                    </aside>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    endereco: state.cartlist.endereco,
    shipping: state.cartlist.shipping,
    token: state.user.token,

})

export default connect(mapStateToProps, { logout, changeState })(SelecioneEntrega);